import Skeleton from '../../Skeleton';
import styles from './InstanceWaitingRoomPage.module.scss'

const InstanceWaitingRoomPage = () => {
    return <div className={styles.container}>
        <div className={styles.header} />
        <div className={styles.content}>
            <Skeleton style={{ width: '25rem', height: '20rem', marginBottom: '2rem' }} />
            <Skeleton style={{ width: '24rem', height: '2rem' }} />
            <Skeleton style={{ width: '18rem', height: '2rem' }} />
        </div>
    </div>

}

export default InstanceWaitingRoomPage;