import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import config from 'config';
import {LanguageSelfName} from 'constants/SupportedLanguages';

const namespaces = [
    'translation',
    'interview',
    'project',
    'admin',
    'auth',
    'profile',
    'onlineInterview',
    'waitingRoom',
    'header',
    'disputes',
    'rate',
    'chat',
    'screeners',
    'welcome',
    'tooltips',
    'notifications',
    'vouchers',
    'promoCodes',
    'public',
    'role',
];

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns: namespaces,
        defaultNS: 'translation',
        fallbackLng: 'en',
        debug: false,
        react: {
            wait: true,
            withRef: true,
            useSuspense: false,
        },
        backend: {
            loadPath: `/${config.localesFolder}/{{lng}}/{{ns}}.json`,
        },
    });

Object.keys(LanguageSelfName).forEach(lang => {
    i18n.addResourceBundle(lang, 'selfName', {
        name: LanguageSelfName[lang],
    });
});

export default i18n;
