import { PageLayout } from "../../PageLayout/PageLayout";
import Skeleton from "../../Skeleton";
import styles from "./ProjectDetailPage.module.scss"

const ProjectDetailPage = () => {
    return <PageLayout>
        <div className={styles.container}>
            <div className={styles.info} />
            <div className={styles.controls}>
                <Skeleton count={4} containerClassName={styles.tabs} style={{ width: '4rem', height: '1.5rem' }} />
                <Skeleton style={{ width: '11.5rem', height: '2.5rem' }} />
            </div>
            <div className={styles.filters}>
                <Skeleton containerClassName={styles.filtersLeft} style={{ height: '3rem', width: '43rem' }} />
                <Skeleton containerClassName={styles.filtersRight} count={2} style={{ height: '3rem', width: '12rem' }} />
            </div>
            <Skeleton count={8} containerClassName={styles.tableHeader} className={styles.tableHeaderItem} />
            <Skeleton count={3} containerClassName={styles.tableRow} className={styles.tableRowItem} />
        </div>
    </PageLayout>
}

export default ProjectDetailPage;