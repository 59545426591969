import classNames from 'classnames';
import { PageLayout } from '../PageLayout/PageLayout';
import styles from './ProfilePublicPage.module.scss'
import Skeleton from '../Skeleton';

const ProfilePublicPage = () => {
    return <PageLayout>
         <div className={styles.container}>
            <div className={classNames(styles.page, styles.left)}>
            <Skeleton circle style={{width: '7.75rem', height: '7.75rem'}} />
            </div>
            <div className={classNames(styles.page, styles.right)}>
                <Skeleton count={4} containerClassName={styles.tabsWrapper} style={{width: '5rem', height: '2rem'}} /> 
            </div>
         </div>
    </PageLayout>
}

export default ProfilePublicPage;