import { PageLayout } from "../../PageLayout/PageLayout";
import Skeleton from "../../Skeleton";
import styles from "./EditScreenerPage.module.scss"

const EditScreenerPage = () => {
    return (
        <PageLayout>
            <div className={styles.container}>
                <div className={styles.questions}>
                    <Skeleton count={2} containerClassName={styles.titles} style={{ width: '6rem', height: '1rem' }} />
                    <Skeleton style={{ width: '6rem', height: '2rem' }} />
                </div>
                <div className={styles.form}>
                    <Skeleton count={2} containerClassName={styles.tabs} style={{ width: '6rem', height: '2rem' }} />
                </div>
            </div>
        </PageLayout>
    )
}

export default EditScreenerPage;